/*All CSS will go in this file*/
TablePage {
  background-color: #00887a;
  height: 100vh;
  width: 100vw;
  margin: 5% ;
  padding: 5%;
  border-radius: 5px;
  overflow: auto;
  overflow-x: auto;
}
html {
  scroll-behavior: smooth;
  overflow: auto;
  overflow-x: hidden;
  background-color: #00887a;
}

* {
  margin: 0;
  padding: 0;
  font-family: Roboto, serif;
  font-weight: normal;
}

Link {
  text-decoration: none;
  color: black;
}

.login-background {
  overflow: auto;
  overflow-x: hidden;
  background-image: url('./img/green-background.png');
  background-size: cover;
  height: 100vh;
  background-repeat: no-repeat;
}

.rounded-rectangle {
  overflow: auto;
  overflow-x: hidden;
  text-align: center;
  background-color: white;
  height: 525px;
  width: 325px;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.im-logo {
  height: 50px;
  margin-left: 43%;
}
.logo-text {
  margin-top: 35px;
}
.login-in-form {
  margin-top: 30px;
}
.form-input {
  border-style: solid;
  border-width: thin;
  border-color: black;
  height: 40px;
  width: 300px;
  margin: 10px;
}

input[type='text'] {
  padding-left: 10px;
}
input[type='password'] {
  padding-left: 10px;
}
label {
  font-weight: bold;
  font-size: 14px;
}
.submit-button {
  width: 300px;
  height: 35px;
  margin-top: 20px;
  background-color: #00887a;
  border-style: none;
  color: white;
  font-size: 16px;
}
.header-imlogo {
  background-color: #fff;
  overflow: hidden;
  height: 80px;
  box-shadow: 0 15px 10px -18px #111;
  display: block;
}
.header-imlogo h5 {
  font-size: 18px;
  display: inline-block;
  margin-left: 5px;
}
.logo-wrapper {
  margin-top: 20px;
  margin-left: 50px;
}

.logout-button {
  margin-right: 5%;
  float: right;
  background-color: #00887a;
  border-style: none;
  color: white;
  font-size: 16px;
  width: 200px;
  height: 35px;
}
/* Following is for error messages for email/password in log-in page. */
.error-email {
  font-size: 10px;
  color: red;
  padding-right: 50%;
  font-weight: normal;
}
.error-password {
  font-size: 10px;
  color: red;
  padding-left: 5%;
  padding-right: 5%;
  font-weight: normal;
}
/*contact page crm page*/
.contact-wrapper {
  background-color: white;
  height: 60px;
  padding: 7px;
  margin-top: 5px;
  border-style: solid;
  border-color: #cecece;
  overflow: auto;
  overflow-x: hidden;
  white-space: nowrap;
  display: flex;
}
.contact-wrapper h6 {
  margin-top: 0.5%;
  flex: 3;
  font-weight: normal;
}
.contact-wrapper h5 {
  margin-left: 3%;
  margin-top: 0.5%;
  flex: 3;
  font-weight: normal;
}
.contacts-group {
  margin: 20px;
}
.to-contact-info-button {
  overflow: auto;
  overflow-x: hidden;
  float: right;
  background-color: #00887a;
  color: white;
  font-size: 16px;
  width: 70px;
  height: 35px;
}
.background-crm {
  background-color: #00887a;
  height: 100vh;
  width: 100vw;
  overflow: auto;
  overflow-x: hidden;
}
.single-contact-page {
  overflow: auto;
  overflow-x: hidden;
  margin: 2% ;
  background-color: white;
  padding: 2%;
  border-radius: 5px;
}
.menu-button {
  overflow: auto;
  overflow-x: hidden;
  background-color: white;
  border-style: solid;
  border-color: black;
  border-width: 2px;
  border-radius: 5px;
  color: black;
  font-size: 14px;
  width: 10%;
  height: 35px;
  margin-left: 10px;
  margin-top: 10px;
}
.add-new-contact-button {
  overflow: auto;
  overflow-x: hidden;
  background-color: white;
  border-style: none;
  color: black;
  font-size: 24px;
  width: 200px;
  height: 35px;
  margin-left: 91%;
  margin-top: 5px;
}

/*New Contact Page*/
.new-contact-container,
.add-comment-wrapper {
  overflow: auto;
  overflow-x: hidden;
  background-color: #00887a;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}
.create-contact-text {
  color: white;
  font-weight: normal;
}
/*Single Page Customer Info Page*/
.contact-info-overview {
  overflow: auto;
  overflow-x: hidden;
  padding-left: 0.5%;
}
.add-new-comment-button {
  display: inline;
  margin-left: 65%;
}

/* Add Comment Page*/
.add-comment-text-wrapper {
  background-color: white;
  padding: 40px;
  text-align: center;
  height: 80%;
  width: 70%;
  border-radius: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -45%);
}

.new-comment {
  margin: 30px;
  height: 300px;
  width: 90%;
  background-color: #cecece;
  border-color: #cecece;
  border-style: solid;
  font-size: 24px;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  resize: none;
  text-align: left;
}
.info-background {
  overflow: auto;
  overflow-x: hidden;
  background-color: #00887a;
  height: 100vh;
  width: 100vw;
}

.comment-wrapper {
  border-style: solid;
  border-color: #efefef;
  margin: 10px;
  padding: 15px;
  border-radius: 10px;
  background-color: #cfcfcf;
}
.sort-select {
  margin-right: 65%;
  margin-left: 2%;
  margin-bottom: 2%;
  margin-top: 10px;
}
